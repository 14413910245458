/**
* Template Name: Regna - v4.7.0
* Template URL: https://bootstrapmade.com/regna-bootstrap-onepage-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  background: #fff;
  color: #666666;
  font-family: "Open Sans", sans-serif;
}

a {
  color: #2dc997;
  text-decoration: none;
}

a:hover, a:active, a:focus {
  color: #2dca98;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #2dc997;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 28px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #49d6a9;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  height: 90px;
  transition: all 0.5s;
  z-index: 997;
  background: rgba(52, 59, 64, 0.9);
}
#header #logo h1 {
  font-size: 32px;
  margin: 0;
  line-height: 1;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
}
#header #logo h1 a, #header #logo h1 a:hover {
  color: #fff;
}
#header #logo img {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 55px;
}
#header.header-transparent {
  background: transparent;
}
#header.header-scrolled {
  background: rgba(52, 59, 64, 0.9);
  height: 70px;
  transition: all 0.5s;
}

.scrolled-offset {
  margin-top: 70px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
.navbar > ul > li {
  white-space: nowrap;
  padding: 10px 0 10px 24px;
}
.navbar a, .navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  color: #fff;
  font-size: 14px;
  padding: 0 4px;
  white-space: nowrap;
  transition: 0.3s;
  letter-spacing: 0.4px;
  position: relative;
  text-transform: uppercase;
}
.navbar a i, .navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar > ul > li > a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -6px;
  left: 0;
  background-color: #2dc997;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out 0s;
}
.navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
  visibility: visible;
  transform: scaleX(1);
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
  color: #fff;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 24px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  color: #666666;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
  color: #2dc997;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(77, 77, 77, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile > ul > li {
  padding: 0;
}
.navbar-mobile a:hover:before, .navbar-mobile li:hover > a:before, .navbar-mobile .active:before {
  visibility: hidden;
}
.navbar-mobile a, .navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #666666;
}
.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
  color: #2dc997;
}
.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
  margin: 15px;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
  color: #2dc997;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background: url(../img/real-estate/homaePage1.jpg) top center;
  background-size: cover;
  position: relative;
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
}
#hero:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero .hero-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
#hero h1 {
  margin: 30px 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  color: #fff;
}
@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
}
#hero h2 {
  color: #eee;
  margin-bottom: 50px;
  font-size: 24px;
}
@media (max-width: 768px) {
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}
#hero .btn-get-started {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}
#hero .btn-get-started:hover {
  background: #2dc997;
  border: 2px solid #2dc997;
}


/*--------------------------------------------------------------
# Blogs Hero Section
--------------------------------------------------------------*/
#hero-blogs {
  width: 100%;
  height: 100vh;
  background: url(../img/real-estate/blogs-bg.jpg) top center;
  background-size: cover;
  position: relative;
}
@media (min-width: 1024px) {
  #hero-blogs {
    background-attachment: fixed;
  }
}
#hero-blogs:before {
  content: "";
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero-blogs .hero-blogs-container {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}
#hero-blogs h1 {
  margin: 30px 0 10px 0;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
  color: #fff;
}
@media (max-width: 768px) {
  #hero-blogs h1 {
    font-size: 28px;
    line-height: 36px;
  }
}
#hero-blogs h2 {
  color: #eee;
  margin-bottom: 50px;
  font-size: 24px;
}
@media (max-width: 768px) {
  #hero-blogs h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
}
#hero-blogs .btn-get-started {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}
#hero-blogs .btn-get-started:hover {
  background: #2dc997;
  border: 2px solid #2dc997;
}



/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/
section {
  overflow: hidden;
}

/* Sections Header
--------------------------------*/
.section-header .section-title {
  font-size: 32px;
  color: #111;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  margin-bottom: 5px;
}
.section-header .section-description {
  text-align: center;
  padding-bottom: 40px;
  color: #999;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 20px 0;
  background-color: whitesmoke;
  min-height: 40px;
  margin-top: 92px;
}
.breadcrumbs h2 {
  font-size: 24px;
  font-weight: 300;
  margin: 0;
}
@media (max-width: 992px) {
  .breadcrumbs h2 {
    margin: 0 0 10px 0;
  }
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #6c757d;
  content: "/";
}
@media (max-width: 768px) {
  .breadcrumbs .d-flex {
    display: block !important;
  }
  .breadcrumbs ol {
    display: block;
  }
  .breadcrumbs ol li {
    display: inline-block;
  }
}

/* About Us Section
--------------------------------*/
#about {
  background: #fff;
  padding: 80px 0;
}
#about .about-container .background {
  min-height: 300px;
  background: url(../img/real-estate/commercial.jpg) center top no-repeat;
  background-size: cover;
  margin-bottom: 10px;
  border-radius: 25px;
}
#about .about-container .content {
  background: #fff;
}
#about .about-container .title {
  color: #333;
  font-weight: 700;
  font-size: 32px;
}
@media (max-width: 768px) {
  #about .about-container .title {
    padding-top: 15px;
  }
}
#about .about-container p {
  line-height: 26px;
}
#about .about-container p:last-child {
  margin-bottom: 0;
}
#about .about-container .icon-box {
  background: #fff;
  background-size: cover;
  padding: 0 0 30px 0;
}
#about .about-container .icon-box .icon {
  float: left;
  background: #fff;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #2dc997;
}
#about .about-container .icon-box .icon i {
  color: #2dc997;
  font-size: 24px;
  line-height: 0;
}
#about .about-container .icon-box .title {
  margin-left: 80px;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 18px;
  text-transform: uppercase;
}
#about .about-container .icon-box .title a {
  color: #111;
}
#about .about-container .icon-box .description {
  margin-left: 80px;
  line-height: 24px;
  font-size: 14px;
}

/* Facts Section
--------------------------------*/
#facts {
  background: #f7f7f7;
  padding: 80px 0 60px 0;
}
#facts .counters span {
  font-size: 48px;
  display: block;
  color: #2dc997;
}
#facts .counters p {
  padding: 0;
  margin: 0 0 20px 0;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
}

/* New Services Section
--------------------------------*/
#new-service {
  background: #fff;
  padding: 80px 0;
}
#new-service .new-service-container .background {
  min-height: 400px;
  background: url(../img/real-estate/home-p.jpg) center top no-repeat;
  background-size: cover;
  border-radius: 25px;
}
#new-service .new-service-container .content {
  background: #fff;
}
#new-service .new-service-container .title {
  color: #333;
  font-weight: 700;
  font-size: 32px;
}
@media (max-width: 768px) {
  #new-service .new-service-container .title {
    padding-top: 15px;
  }
}
#new-service .new-service-container p {
  line-height: 26px;
}
#new-service .new-service-container p:last-child {
  margin-bottom: 0;
}
#new-service .new-service-container .icon-box {
  background: #fff;
  background-size: cover;
  padding: 0 0 30px 0;
}
#new-service .new-service-container .icon-box .icon {
  float: left;
  background: #fff;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #2dc997;
}
#new-service .new-service-container .icon-box .icon i {
  color: #2dc997;
  font-size: 24px;
  line-height: 0;
}
#new-service .new-service-container .icon-box .title {
  margin-left: 80px;
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 18px;
  text-transform: uppercase;
}
#new-service .new-service-container .icon-box .title a {
  color: #111;
}
#new-service .new-service-container .icon-box .description {
  margin-left: 80px;
  line-height: 24px;
  font-size: 14px;
}


/* Services Section
--------------------------------*/
#services {
  background: #fff;
  background-size: cover;
  padding: 80px 0 60px 0;
}
#services .box {
  padding: 50px 20px;
  margin-bottom: 50px;
  text-align: center;
  border: 1px solid #e6e6e6;
  height: 200px;
  position: relative;
  background: #fafafa;
}
#services .icon {
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  transition: 0.2s;
  border-radius: 50%;
  border: 6px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 72px;
  height: 72px;
  background: #2dc997;
}
#services .icon a {
  display: inline-block;
}
#services .icon i {
  color: #fff;
  font-size: 24px;
  line-height: 0;
}
#services .box:hover .icon {
  background: #fff;
  border: 2px solid #2dc997;
}
#services .box:hover .icon i {
  color: #2dc997;
}
#services .box:hover .icon a {
  color: #2dc997;
}
#services .title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
#services .title a {
  color: #111;
}
#services .description {
  font-size: 14px;
  line-height: 24px;
}

/* Journey Section
--------------------------------*/
#journey {
  background: #fff;
  background-size: cover;
  padding: 80px 0 60px 0;
}
#journey .box {
  padding: 50px 20px;
  margin-bottom: 50px;
  text-align: center;
  border: 1px solid #e6e6e6;
  height: 200px;
  position: relative;
  background: #fafafa;
  border-radius: 15px;
}
#journey .icon {
  position: absolute;
  top: -36px;
  left: calc(50% - 36px);
  transition: 0.2s;
  border-radius: 50%;
  border: 6px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 72px;
  height: 72px;
  background: #2dc997;
}
#journey .icon a {
  display: inline-block;
}
#journey .icon i {
  color: #fff;
  font-size: 24px;
  line-height: 0;
}
#journey .box:hover .icon {
  background: #fff;
  border: 2px solid #2dc997;
}
#journey .box:hover .icon i {
  color: #2dc997;
}
#journey .box:hover .icon a {
  color: #2dc997;
}
#journey .title {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
#journey .title a {
  color: #111;
}
#journey .description {
  font-size: 14px;
  line-height: 24px;
}

/* Call To Action Section
--------------------------------*/
#call-to-action {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/trucks/truck-5.jpg) fixed center center;
  background-size: cover;
  padding: 80px 0;
}
@media (max-width: 1024px) {
  #call-to-action {
    background-attachment: scroll;
  }
}
#call-to-action .cta-title {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}
#call-to-action .cta-text {
  color: #fff;
}
@media (min-width: 769px) {
  #call-to-action .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
#call-to-action .cta-btn {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 30px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}
#call-to-action .cta-btn:hover {
  background: #2dc997;
  border: 2px solid #2dc997;
}

/* Portfolio Section
--------------------------------*/
#portfolio {
  background: #f7f7f7;
  padding: 80px 0;
}
#portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  border-radius: 50px;
}
#portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 12px 18px 14px 18px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #666666;
  margin: 0 5px 10px 5px;
  transition: all ease-in-out 0.3s;
  background: white;
  border-radius: 4px;
}
#portfolio #portfolio-flters li:hover, #portfolio #portfolio-flters li.filter-active {
  color: #fff;
  background: #2dc997;
}
#portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}
#portfolio .portfolio-item {
  margin-bottom: 30px;
  overflow: hidden;
}
#portfolio .portfolio-item img {
  position: relative;
  top: 0;
  transition: all 0.6s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 20px;
}
#portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: -50px;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  background: #2dc997;
  padding: 15px 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
#portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
}
#portfolio .portfolio-item .portfolio-info p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}
#portfolio .portfolio-item .portfolio-info .preview-link, #portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 50px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: white;
  transition: ease-in-out 0.3s;
}
#portfolio .portfolio-item .portfolio-info .preview-link:hover, #portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #a4ebd4;
}
#portfolio .portfolio-item .portfolio-info .details-link {
  right: 15px;
}
#portfolio .portfolio-item:hover img {
  top: -30px;
}
#portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
  bottom: 0;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}
.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #2dc997;
}
.portfolio-details .portfolio-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #2dc997;
}
.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(102, 102, 102, 0.08);
}
.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}
.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}
.portfolio-details .portfolio-description {
  padding-top: 30px;
}
.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.portfolio-details .portfolio-description p {
  padding: 0;
}

/* Team Section
--------------------------------*/
#team {
  background: #fff;
  padding: 80px 0 60px 0;
}
#team .member {
  text-align: center;
  margin-bottom: 20px;
}
#team .member .pic {
  margin-bottom: 15px;
  overflow: hidden;
  height: 260px;
  border-radius: 20px;
}
#team .member .pic img {
  max-width: 100%;
}
#team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}
#team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}
#team .member .social {
  margin-top: 15px;
}
#team .member .social a {
  color: #b3b3b3;
}
#team .member .social a:hover {
  color: #2dc997;
}
#team .member .social i {
  font-size: 18px;
  margin: 0 2px;
}

/* Contact Section
--------------------------------*/
#contact {
  background: #f7f7f7;
  padding: 80px 0 40px 0;
}
#contact #google-map {
  height: 300px;
  margin-bottom: 20px;
}
#contact .info {
  color: #333333;
}
#contact .info i {
  font-size: 32px;
  color: #2dc997;
  float: left;
  line-height: 0;
}
#contact .info p {
  padding: 0 0 10px 50px;
  margin-bottom: 20px;
  line-height: 22px;
  font-size: 14px;
}
#contact .info .email p {
  padding-top: 5px;
}
#contact .social-links {
  padding: 20px 0;
}
#contact .social-links a {
  font-size: 18px;
  background: #333;
  color: #fff;
  line-height: 1;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
#contact .social-links a i {
  line-height: 0;
}
#contact .social-links a:hover {
  background: #2dc997;
  color: #fff;
}
#contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
#contact .php-email-form .error-message br + br {
  margin-top: 25px;
}
#contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
#contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
#contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
#contact .php-email-form input, #contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}
#contact .php-email-form input::focus, #contact .php-email-form textarea::focus {
  background-color: #2dc997;
}
#contact .php-email-form button[type=submit] {
  background: #2dc997;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
}
#contact .php-email-form button[type=submit]:hover {
  background: #51d8ad;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: #343b40;
  padding: 30px 0;
  color: #fff;
  font-size: 14px;
}
#footer .copyright {
  text-align: center;
}
#footer .credits {
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  color: #ccc;
}